










































































































import { defineComponent, computed, ref, onMounted } from '@vue/composition-api';
import { useUserState, useUserActions } from '@/store';
import axios from 'axios';
import MAgreementStudent from './m-agreement-student.vue';
import MAgreementGuardian from './m-agreement-guardian.vue';
import MAgreementConsent from './m-agreement-consent.vue';

export default defineComponent({
  name: 'AgreementAdk',

  components: {
    MAgreementStudent,
    MAgreementGuardian,
    MAgreementConsent
  },

  props: {
    isOpen: {
      required: true,
      type: Boolean
    },
    studentDoc: {
      required: true,
      type: Object
    },
    getStudentDoc: {
      type: Function,
      default: () => {}
    },
    updateDoc: {
      type: Function,
      default: () => {}
    }
  },
  setup(props: any, ctx: any) {
    const isOpenDialog = computed({
      get: () => props.isOpen,
      set: newVal => {
        ctx.emit('toggle');
      }
    });

    const date = ref('');
    const offerAlert = ref({
      color: '',
      msg: ''
    });
    const { user }: any = useUserState(['user']);
    const { updateProgramState } = useUserActions(['updateProgramState']);
    const { getDocumentByIdAndType } = useUserActions(['getDocumentByIdAndType']);
    const isUserInvalid = ref(false);
    const isGuardianInvalid = ref(false);
    const isUserPhoneValid = ref(false);
    const isGuardianPhoneValid = ref(false);
    const agreementContent = ref('');
    const userData = ref();
    const guardianData = ref();
    const submitting = ref(false);
    const guardianTerms = ref(false);
    const userTerms = ref(false);

    function getAge() {
      const birthDate = new Date(date.value);
      const todayDate = new Date();
      let years = todayDate.getFullYear() - birthDate.getFullYear();
      if (
        todayDate.getMonth() < birthDate.getMonth() ||
        (todayDate.getMonth() === birthDate.getMonth() && todayDate.getDate() < birthDate.getDate())
      ) {
        years--;
      }
      return years;
    }

    const isAdult = computed(() => {
      if (getAge() >= 18) return true;
      return false;
    });

    onMounted(() => {
      getAge();
    });

    getDocumentByIdAndType({
      document_type: 'StudentPortfolio',
      document_id: user.value?._id,
      public_id: props.studentDoc?.data?._id?.toString()
    }).then(res => {
      if (res) {
        date.value = res.date;
      }
    });

    function setUserValidation(val) {
      isUserInvalid.value = val;
    }

    function setUserPhoneValidation(val) {
      isUserPhoneValid.value = val;
    }

    function setGuardianValidation(val) {
      isGuardianInvalid.value = val;
    }

    function setGuardianPhoneValidation(val) {
      isGuardianPhoneValid.value = val;
    }

    function getUserData(val) {
      userData.value = { ...val };
    }

    function getGuardianData(val) {
      guardianData.value = {
        guardian_first_name: val.first_name,
        guardian_last_name: val.last_name,
        guardian_phone: val.guardian_phone,
        guardian_email: val.email,
        guardian_relationship: val.relationship,
        social_security_number: val.social_security_number,
        guardian_street_address: val.streetAddress
      };
    }

    function setTerms(val) {
      guardianTerms.value = val;
    }

    function setUserTerms(val) {
      userTerms.value = val;
    }

    async function fetchConsentDetails() {
      const API = process.env.VUE_APP_STUDENT_SERVICE;

      try {
        const res = await axios.get(
          `${API}/agreement?program_id=${
            ctx.root.$route.params.programId || props.studentDoc?.data?.program_id
          }&public_id=${props.studentDoc?.data?._id?.toString()}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
            }
          }
        );
        if (res.data) {
          agreementContent.value = res.data.agreement_consent_text;
        }
      } catch (error) {
        console.log(error);
      }
    }

    fetchConsentDetails();

    async function acceptOffer() {
      try {
        submitting.value = true;
        const API: any = `${process.env.VUE_APP_STUDENT_SERVICE}/agreement`;
        let payload = {
          type: '',
          data: {}
        };
        if (!isAdult.value) {
          payload = {
            type: 'minor',
            data: {
              program_id: ctx.root.$route.params.programId || props.studentDoc?.data?.program_id,
              user_id: user.value?._id.toString(),
              signed_agreement_content: agreementContent.value,
              consent_date_time: new Date(),
              ...userData.value,
              ...guardianData.value
            }
          };
        } else {
          payload = {
            type: 'adult',
            data: {
              program_id: ctx.root.$route.params.programId || props.studentDoc?.data?.program_id,
              user_id: user.value?._id.toString(),
              signed_agreement_content: agreementContent.value,
              consent_date_time: new Date(),
              ...userData.value
            }
          };
        }
        const res = await axios.post(API, payload, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        });

        if (res.data) {
          offerAlert.value.msg = 'Offer Accepted';
          offerAlert.value.color = 'green';
          updateProgramState(res.data.data);
          props.updateDoc();
          // const offerAdkIndex = props?.studentDoc?.data?.adks?.findIndex(
          //   adk => adk.name === 'offer'
          // );
          const offerAdkIndex = props?.studentDoc?.data?.adks?.findIndex(adk =>
            adk.fields?.find(field => field.name === 'm-offer-table')
          );
          // if (offerAdkIndex > 0) {
          //   console.log('ctx.root', ctx.root);
          //   // if (props?.redirectUrl) {
          //   //   window.location.href = `${props?.redirectUrl}`;
          //   // } else {
          //   //   ctx.root.$router.push({
          //   //     name: 'guide',
          //   //     params: {
          //   //       programId: ctx.root.$route.params.programId || props.studentDoc?.data?.program_id,
          //   //       page: offerAdkIndex
          //   //     }
          //   //   });
          //   // }
          // }
          submitting.value = false;
          ctx.emit('toggle');
        }
      } catch (error: any) {
        offerAlert.value.msg = error?.response?.data?.error?.description;
        offerAlert.value.color = 'red';
        submitting.value = false;
      }
    }

    return {
      isOpenDialog,
      getGuardianData,
      setTerms,
      setUserTerms,
      getUserData,
      fetchConsentDetails,
      setUserPhoneValidation,
      setGuardianPhoneValidation,
      setGuardianValidation,
      setUserValidation,
      getAge,
      isAdult,
      isUserInvalid,
      isGuardianInvalid,
      isUserPhoneValid,
      isGuardianPhoneValid,
      agreementContent,
      acceptOffer,
      offerAlert,
      submitting,
      guardianTerms,
      userTerms
    };
  }
});
